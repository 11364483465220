import axios from 'axios';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import Modal from '../../../../SharedComponents/Modal';
import PlusCircle from '../../../../SharedComponents/Svg/PlusCircle';
import folder from '../../../../assets/img/folder.svg';
import { useFormikContext } from 'formik';

export default function AddGalleryAlbumToPage(props) {
  const { languageId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { values } = useFormikContext();
  return (
    <div className="border p-4 shadow">
      <span className="font-bold">{t('Gallery Album')}</span>
      <hr className="mb-4" />
      <div className="flex flex-col divide-y space-y-2">
        {values?.glAlbumItemForPages?.length > 0 &&
          values?.glAlbumItemForPages?.map((item, index) => {
            return (
              <div className="flex space-x-2" key={index}>
                <div>
                  <div className="text-center">
                    {item.isVideo ? (
                      <video className="h-20" controls>
                        <source src={`${window.env.REACT_APP_IMAGE_PATH}${item.imageUrl}`} type="video/mp4" />
                        {t('Your browser does not support the video tag.')}
                      </video>
                    ) : (
                      <img className="h-20" src={`${window.env.REACT_APP_IMAGE_PATH}${item.imageUrl}`} alt="ss" />
                    )}
                  </div>
                </div>
                {item.gitLangForAlbumItems.map((e, index) => {
                  return (
                    <div index={index} key={e.id}>
                      {languageId === e.languageId && (
                        <div key={e.id}>
                          <b className="block mt-1 mb-2">{e.title}</b>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
      <hr className="mt-4" />
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-lightgreen space-x-1 mt-4  ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" />
        <span>{t('Choose Album')}</span>
      </button>
      {isOpen && (
        <ModalContainer
          //   id={id}
          //   typeId={typeId}
          languageId={languageId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}{' '}
    </div>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, languageId } = props;
  const { setFieldValue, values } = useFormikContext();
  const [albumId, setAlbumId] = useState(null);
  const { t, i18n } = useTranslation();
  let initialState = {
    typeId: 1,
    paginationFilter: {
      pageNumber: 1,
      pageSize: 9999,
      isPaging: false,
      langId: 0,
    },
  };
  const { data, isDone, isLoading, isError } = useQuery(['galleryAlbums', initialState], queryFunction, {
    staleTime: 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  const onSubmit = () => {
    setFieldValue('albumId', albumId);
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={onSubmit}
        title={t('Choose Album')}
        // isLoading={isLoading}
        submitType="button"
        add={true}
        className="sm:max-w-3xl"
      >
        <div className=" items-center grid grid-cols-3 lg:grid-cols-3 gap-8 mt-4 mb-8">
          {data?.data.map((item) => {
            return (
              <div
                onClick={() => {
                  setAlbumId(item.id);
                }}
                className={`relative bg-white  z-5 rounded shadow-md border py-4 px-2 ${
                  albumId === item.id ? 'bg-gray-300' : ''
                }`}
              >
                <div className="">
                  <div className="text-center">
                    <img className="inline-block rounded  " alt={folder} src={folder} />
                    {item.glAlbumLngs.map((e, index) => {
                      return (
                        <div index={index} key={e.id}>
                          {languageId === e.languageId && (
                            <div key={e.id}>
                              <b className="block mt-1 mb-2">{e.title}</b>
                            </div>
                          )}
                        </div>
                      );
                    })}

                    <span className="block text-sm text-gray-400">{/* {itemCount} {t('Items')} */}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* {publicFolderId !== data?.data[0]?.id && (
          <div className="flex justify-end">
            <button
              onClick={() => {
                setPublicFolderId(data?.data[0]?.id);
              }}
              className={`flex focus:outline-none items-center ${
                i18n.language === 'en' ? '' : 'flex-row-reverse  '
              } justify-start border pr-4  bg-white hover:bg-gray-100 rounded text-gray-800 transition duration-500`}
            >
              <ChevronLeft className="w-5 h-5" /> <span>{t('GO BACK')}</span>
            </button>
          </div>
        )} */}
        {/* <div className="flex items-center grid grid-cols-3 lg:grid-cols-3 gap-8 mt-4 mb-8">
          {data?.data[0]?.id === publicFolderId &&
            data?.data?.slice(1).map((e) => {
              return (
                <FileHolder
                  setPublicFolderId={setPublicFolderId}
                  folderData={e}
                  name={e.name}
                  key={e.id}
                  id={e.id}
                  typeId={activeTab}
                  itemCount={e.itemCount}
                />
              );
            })}
          {publicFolder &&
            publicFolder.data &&
            publicFolder.data.data &&
            publicFolder?.data.data.astAssets
              .filter((e) => e.typeId === activeTab)
              .map((e) => {
                return (
                  <ImageHolder
                    assetCollection={assetCollection}
                    setAssetCollection={setAssetCollection}
                    languageId={languageId}
                    asset={e}
                    key={e.id}
                  />
                );
              })}
        </div> */}
      </Modal>
    </>
  );
};

// GalleryAlbumsApi
export const queryFunction = async (e) => {
  const query = await axios.post('/GlAlbum/GetAll', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
